import { render, staticRenderFns } from "./banner.vue?vue&type=template&id=c0e4d410&scoped=true&"
var script = {}
import style0 from "./banner.vue?vue&type=style&index=0&id=c0e4d410&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0e4d410",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LazyImg: require('/app/landing_generator/components/lazy-img.vue').default})
