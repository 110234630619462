//
//
//
//
//
//
//
//
//

// ?ab=experimental_type-one
const AB_TEST_QUERY_PARAM = 'ab';
const ID_SEPARATOR = '_';

export default {
  inject: ['testName', 'showList', 'variants', 'updateVariants'],
  props: {
    name: {
      type: String,
      required: true,
    },
    displayed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      const testName = this.testName;
      const variantName = this.name;
      return `${testName}${ID_SEPARATOR}${variantName}`;
    },
    isActive() {
      const abQuery = this.$route.query[AB_TEST_QUERY_PARAM];

      if (abQuery) {
        switch (typeof abQuery) {
          case 'object':
            return abQuery?.includes(this.id);
          case 'string':
            return abQuery === this.id;
          default:
            return false;
        }
      } else {
        return false;
      }
    },
    isShow() {
      const showList = this.showList;
      return showList()?.[this.name];
    },
  },
  created() {
    this.createVariant();
  },
  methods: {
    createVariant() {
      this.updateVariants({
        [this.name]: {
          defaultDisplayed: this.displayed,
          isActive: this.isActive,
        },
      });
    },
  },
};
