//
//
//
//
//
//

export default {
  provide() {
    // Возвращаем объект с функцией для обновления variants
    const updateVariants = (newVariants) => {
      // Используем Object.assign для обновления объекта variants
      this.variants = { ...this.variants, ...newVariants };
    };

    return {
      testName: this.name,
      showList: () => this.showList, // Геттер для showList
      variants: this.variants,
      updateVariants,
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      variants: {},
    };
  },
  computed: {
    showList() {
      const variants = this.variants;
      const activeVariantsName = Object.entries(variants).reduce(
        (acc, [variantName, variantValue]) => {
          if (variantValue.isActive) acc.push(variantName);
          return acc;
        },
        [],
      );

      const showList = Object.keys(this.variants).reduce((acc, variantName) => {
        if (activeVariantsName.length) {
          acc[variantName] = activeVariantsName.includes(variantName);
        } else if (Object.keys(this.variants).length > 1) {
          acc[variantName] = variants[variantName].defaultDisplayed;
        }

        return acc;
      }, {});

      return showList;
    },
  },
};
